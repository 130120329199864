import React, {useState, useRef} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PhoneMask from "../elements/masks/PhoneMask"
import {useForm} from "react-hook-form";
import {makeStyles, MuiThemeProvider, createTheme} from "@material-ui/core/styles";
import styled from 'styled-components';

const THEME = createTheme({
    typography: {
        "fontFamily": ` "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

const Title = styled.h1`
  text-align: center;
`

const AgreeWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle;
`
const AgreeErrorText = styled.span`
  color: red;
  font-size: 0.75rem;
  margin: 3px 0 0;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`
const GreenText = styled.span`
  color: green;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`
const ButtonSubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`

const useStyles = () => makeStyles(theme => ({
    required: {
        "& .Mui-error": {
            color: "red"
        }, "& .MuiFormHelperText-root": {
            color: "red"
        }, "& .MuiInputLabel-root": {
            color: "green"
        },

    }, optional: {
        "& .Mui-error": {
            color: "red"
        }, "& .MuiFormHelperText-root": {
            color: "gray"
        }
    }, checkboxRequired: {
        color: 'green'
    }
}));


export default function Registration(props) {
    const signup_url = props.url
    const classes = useStyles()();
    const {register, handleSubmit, errors, watch, setError, clearErrors} = useForm();
    const password = useRef({});
    password.current = watch("password", "");
    const [open, setOpen] = useState(false);
    const [agree, setAgree] = useState(false);
    const [success_registration, setSuccessRegistration] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);

    };
    const checkAgree = () => {
        setAgree(!agree)
    }
    const refuse = () => {
        if (agree) {
            setAgree(false)
        }
        setOpen(false);
    }
    const approveAndClose = () => {
        if (!agree) {
            setAgree(true)
            clearErrors("agree")
        }
        setOpen(false);
    };

    const onSubmit = data => {
        const user = {
            user: data
        }
        fetch(signup_url, {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify(user)
        }).then(async response => {
            const data = await response.json();
            if (!response.ok) {
                Object.keys(data).forEach(key => {
                    setError(key, {
                        type: "manual", message: data[key]
                    });
                });
            } else {
                setSuccessRegistration(true)
            }
        }).catch((e) => {
            console.log('Error: ' + e.message);
            console.log(e.response);
        });
    }

    function sitePolicy() {
        return {__html: props.site_policy};
    }

    function registrationText() {
        return {__html: props.registration_text};
    }

    if (!success_registration) {
        return (<MuiThemeProvider theme={THEME}>
            <Title>Регистрация </Title>
            {props.show && <p>Перед регистрацией на сайте, для исполнения требований законодательства,
                Вы обязаны ознакомиться с <GreenText onClick={handleClickOpen}>соглашением на обработку
                    персональных
                    данных и условиями пользовательского соглашения</GreenText>.
            </p>}
            <p>Поля отмеченные <GreenText> зеленым цветом</GreenText> обязательны для заполнения.</p>
            <div dangerouslySetInnerHTML={registrationText()}/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth>
                    <TextField
                        autoFocus
                        error={!!errors.lastname}
                        name="lastname"
                        inputRef={register({required: true})}
                        label="Фамилия"
                        placeholder="Ваша фамилия"
                        helperText={errors.lastname ? 'Вы забыли указать фамилию' : ''}
                        fullWidth
                        margin="normal"
                        className={classes.required}
                    />
                    <TextField
                        error={!!errors.name}
                        name="name"
                        inputRef={register({required: true})}
                        label="Имя"
                        placeholder="Ваше имя"
                        helperText={errors.name ? 'Вы забыли указать имя' : ''}
                        fullWidth
                        margin="normal"
                        className={classes.required}
                    />
                    <TextField
                        error={!!errors.surname}
                        name="surname"
                        inputRef={register({required: true})}
                        label="Отчество"
                        placeholder="Ваше отчество"
                        helperText={errors.surname ? 'Вы забыли указать отчество' : ''}
                        fullWidth
                        margin="normal"
                        className={classes.required}
                    />
                    <TextField
                        name="street"
                        inputRef={register}
                        label="Улица"
                        placeholder="Ваша улица"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        error={!!errors.address}
                        name="address"
                        inputRef={register({required: true})}
                        label="Участок"
                        placeholder="Ваш участок"
                        helperText={errors.address ? 'Вы забыли указать участок' : ''}
                        fullWidth
                        margin="normal"
                        className={classes.required}
                    />
                    <TextField
                        name="land_area"
                        inputRef={register}
                        label="Площадь участка (кв.м)"
                        placeholder="Напишите площадь участка в кв.м"
                        fullWidth
                        margin="normal"
                        className={classes.optional}
                    />
                    <TextField
                        name="cadastre"
                        inputRef={register}
                        label="Кадастровый номер"
                        placeholder="Кадастровый номер участка"
                        fullWidth
                        margin="normal"
                        className={classes.optional}
                    />
                    <TextField
                        error={!!errors.registration_address}
                        name="registration_address"
                        inputRef={register({required: true})}
                        label="Адрес регистрации/почтовый адрес"
                        placeholder="Ваш адрес регистрации"
                        helperText={errors.registration_address ? 'Вы забыли указать адрес регистрации' : ''}
                        fullWidth
                        margin="normal"
                        className={classes.required}
                    />
                    <TextField
                        error={!!errors.phone}
                        name="phone"
                        inputRef={register({
                            required: true,
                            maxLength: 17,
                            pattern: /[+][7][(][1-9][\d][\d][)][ ][\d][\d][\d][-][\d][\d][-][\d][\d]/g
                        })}
                        label="Номер мобильного телефона"
                        placeholder="Ваш мобильный номер"
                        helperText={errors.phone ? 'Вы некорректно указали номер телефона' : ''}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            inputComponent: PhoneMask,
                        }}
                        className={classes.required}
                    />
                    <TextField
                        name="phone_other"
                        inputRef={register}
                        label="Дополнительный телефон"
                        placeholder="Дополнительный телефон"
                        fullWidth
                        margin="normal"
                        InputProps={{
                            inputComponent: PhoneMask,
                        }}
                        className={classes.optional}
                    />
                    <TextField
                        name="principal"
                        inputRef={register}
                        label="Доверитель (если Вы представитель по доверенности)"
                        placeholder="Ф.И.О. доверителя"
                        fullWidth
                        margin="normal"
                        className={classes.optional}
                    />
                    <TextField
                        error={!!errors.email}
                        name="email"
                        inputRef={register({
                            required: "Вы забыли указать email", pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Некорректно указан email"
                            }
                        })}
                        label="Email"
                        placeholder="Ваш email"
                        helperText={errors.email ? errors.email.message : ""}
                        fullWidth
                        margin="normal"
                        className={classes.required}
                    />
                    <AgreeWrapper>
                        <Checkbox
                            checked={agree}
                            onClick={checkAgree}
                            name="agree"
                            inputRef={register({required: true})}
                            color="primary"
                        />
                        {

                        }
                        {props.show &&
                            <GreenText onClick={handleClickOpen}>Согласен на обработку персональных данных и с
                                условиями
                                пользовательского соглашения</GreenText>}
                        {!props.show && <GreenText>Согласен на обработку персональных данных и с условиями
                            пользовательского соглашения</GreenText>}
                    </AgreeWrapper>
                    {errors.agree &&
                        <AgreeErrorText>Вы должны дать согласие на обработку персональных данных и с условиями
                            пользовательского соглашения</AgreeErrorText>}
                    <FormControlLabel
                        control={<Checkbox
                            name="mailing"
                            inputRef={register}
                            color="primary"
                        />}
                        label="Согласен получать письма с новостями от правления"
                    />
                    {!props.protect_member &&
                        <FormControlLabel
                        control={<Checkbox
                        name="member"
                        inputRef={register}
                        color="primary"
                        />}
                        label="Член товарищества"
                        />
                    }
                    <FormControlLabel
                        control={<Checkbox
                            name="owner"
                            inputRef={register}
                            color="primary"
                        />}
                        label="Я - собственник"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            name="representative"
                            inputRef={register}
                            color="primary"
                        />}
                        label="Я - представитель по доверенности"
                    />
                    <TextField
                        error={!!errors.password}
                        name="password"
                        inputRef={register({
                            required: "Вы должны указать пароль", minLength: {
                                value: 6, message: "Пароль должен содержать минимум 6 символов"
                            }
                        })}
                        label="Пароль"
                        type="password"
                        autoComplete="off"
                        helperText={errors.password ? errors.password.message : "Пароль должен содержать минимум 6 символов"}
                    />
                    <TextField
                        error={!!errors.password_confirmation}
                        name="password_confirmation"
                        label="Подтверждение пароля"
                        placeholder="Повторите введенный пароль"
                        type="password"
                        autoComplete="off"
                        inputRef={register({
                            validate: value => value === password.current || "Пароли не совпадают"
                        })}
                        helperText={errors.password_confirmation ? errors.password_confirmation.message : ""}
                    />
                </FormControl>
                <ButtonSubmitWrapper>
                    <Button variant="contained" color="primary" type="submit">
                        Зарегистрироваться
                    </Button>
                </ButtonSubmitWrapper>
            </form>
            <Dialog
                open={open}
                fullWidth
                maxWidth={'md'}
                onClose={handleClose}
            >
                <DialogContent>
                    <div dangerouslySetInnerHTML={sitePolicy()}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={refuse} variant="contained" color="secondary">
                        Не принимаю
                    </Button>
                    <Button onClick={approveAndClose} variant="contained" color="primary">
                        Принимаю
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiThemeProvider>)
    } else {
        return (<div>
            <Title>Регистрация </Title>
            <p>
                Вы успешно зарегистрировались на сайте. В течение нескольких минут Вы получите письмо с инструкцией
                по подтверждению вашей учётной записи.
                Подтвердите учетную запись, перейдяя по ссылке из письма.
            </p>
        </div>)
    }

}



