import ReactOnRails from 'react-on-rails';

import GalleryShow from '../../components/old/gallery/GalleryShow';
import Bulletin from '../../components/front/bulletins/Bulletin';
import Registration from '../../components/front/Registration';
import RegistrationOrange from '../../components/front/RegistrationOrange';
import ModalPDFViewerFilename from "../../components/elements/pdf/ModalPDFViewerFilename";
import Attachments from "../../components/elements/files/Attachments";
import VotingBulletin from "../../bundles/front/bulletins/VotingBulletin";

ReactOnRails.register({
    Attachments, VotingBulletin, GalleryShow, Registration, RegistrationOrange, ModalPDFViewerFilename, Bulletin
});
