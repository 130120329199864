import React from "react";
import {ThemeProvider} from "@mui/material";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled'

import {createTheme} from "@mui/material";
import getClassNameForExtension from "font-awesome-filetypes";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
    },
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`

const FilesTitle = styled.div`
  text-align: center;
  font-size: 16px;
`
const FilesTable = styled.div`
  display: grid;
  grid-template-columns: 80% 15% 5%;
  margin: 10px 0;
  border-bottom: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-left: 1px solid #CCCCCC;
`
const FilesCell = styled.div`
  padding: 4px;
  border-top: 1px solid #CCCCCC;
`

const QuestionWrapper = styled.div`
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid white;
  transition-property: border;
  transition-duration: 150ms;
  ${({error}) => error && `
    border: 1px solid #f50057;
  `}
`

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const VotedSuccess = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`

const initErrors = (questions) => {
    let init_errors = {}
    questions.map((question) => {
            init_errors[question.id] = false
        }
    )
    return init_errors
}

const VotingBulletin = ({token, bulletin}) => {
    const [errors, setErrors] = React.useState(initErrors(bulletin.questions))
    const [form_values, setFormValues] = React.useState({})
    const [code, setCode] = React.useState(null)
    const [codeSend, setCodeSend] = React.useState(false)

    const createDescription = () => {
        return {__html: bulletin.description};
    }

    const formChange = (e) => {
        setFormValues({...form_values, [e.target.name]: e.target.value})
        setErrors((errors) => ({...errors, [e.target.name]: false}))
    }

    const validate = (action) => {
        let new_errors = {}
        for (let key in errors) {
            new_errors[key] = !form_values.hasOwnProperty(key);
        }
        if (Object.values(new_errors).includes(true)) {
            toast.error("Вы ответили не на все вопросы в бюллетени")
            setErrors(() => (new_errors))
        } else {
            action()
        }
    }

    const createVote = () => {
        let voteData = {
            vote: {
                voting_bulletin_id: bulletin.id,
                questions_and_answers: form_values,
                code: code
            }
        }
        fetch('/profile/voting_bulletins', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            }, body: JSON.stringify(voteData)
        }).then(res => {
            if (res.ok) {
                window.location.replace(`/profile/voting_bulletins/${bulletin.id}/success`)
            } else {
                if (res.status === 403) {
                    toast.error('Вы ввели некорректный код подтверждения')
                } else {
                    toast.error('Возникла ошибка при голосовании')
                }
            }
        }).catch(e => {
            toast.error('Возникла ошибка при голосовании')
        })
    }

    const codeMessage = () => {
        if (bulletin.confirmation_type === 1) {
            return 'На почту отправлен код подтверждения. Введите его ниже.'
        } else if (bulletin.confirmation_type === 2) {
            return 'На телефон отправлен код подтверждения. Введите его ниже.'
        }
    }

    const sendCode = () => {
        fetch(`/profile/voting_bulletins/${bulletin.id}/send_code`)
            .then(res => {
                if (res.ok) {
                    toast.success('Код отправлен')
                    setCodeSend(true)
                } else {
                    toast.error('Ошибка отправки кода')
                }
            }).catch(e => {
            toast.error('Ошибка отправки кода')
        })
    }

    const docs = bulletin.docs.map((file, index) => (
        <React.Fragment key={index}>
            <FilesCell>
                <i className={"fas " + getClassNameForExtension(file.filename.split('.').pop())}></i>&nbsp;
                {file.filename}
            </FilesCell>
            <FilesCell>
                {Math.round(file.size / 10.24) / 100} КБ
            </FilesCell>
            <FilesCell>
                <a href={file.url} download>
                    <i className="fas fa-download text-success"></i>
                </a>
            </FilesCell>
        </React.Fragment>
    ))

    return (
        <ThemeProvider theme={theme}>
            <ToastContainer/>
                <Wrapper>
                    <Title>{bulletin.title}</Title>
                    <div dangerouslySetInnerHTML={createDescription()}/>
                    {bulletin.docs.length > 0 &&
                        <>
                            <FilesTitle>Документы</FilesTitle>
                            <FilesTable>
                                <FilesCell>Название</FilesCell>
                                <FilesCell align="right">Размер</FilesCell>
                                <FilesCell/>
                                {docs}
                            </FilesTable>
                        </>
                    }
                    <FormControl onChange={formChange}>
                        {bulletin.questions.map((question, index) => (
                                <React.Fragment key={index}>
                                    <QuestionWrapper error={errors[question.id]}>
                                        <div dangerouslySetInnerHTML={{__html: question.name}}/>
                                        <RadioGroup
                                            key={question.id}
                                            name={question.id.toString()}
                                        >
                                            {question.answers.map(answer => <FormControlLabel key={answer.id}
                                                                                              value={answer.id}
                                                                                              control={<Radio/>}
                                                                                              label={answer.name}/>)}
                                        </RadioGroup>
                                    </QuestionWrapper>
                                </React.Fragment>
                            )
                        )}
                    </FormControl>
                    <BottomWrapper>
                        {(bulletin.confirmation_type !== 0 && codeSend) &&
                            <>
                                <div>{codeMessage()}</div>
                                <TextField id="code" label="Код подтверждения" variant="outlined"
                                           onChange={(e) => setCode(e.target.value)}/>
                            </>
                        }
                        {(bulletin.confirmation_type !== 0 && !codeSend) &&
                            <Button size="large" variant="contained" color="success"
                                    onClick={() => validate(sendCode)}>Запросить код</Button>
                        }
                        {(bulletin.confirmation_type === 0 || codeSend) &&
                            <Button size="large" variant="contained" color="success"
                                    onClick={() => validate(createVote)}>Проголосовать</Button>
                        }
                    </BottomWrapper>
                </Wrapper>
        </ThemeProvider>
    )
}
export default VotingBulletin

